import { render, staticRenderFns } from "./Suspended.vue?vue&type=template&id=61928f32&"
import script from "./Suspended.vue?vue&type=script&lang=js&"
export * from "./Suspended.vue?vue&type=script&lang=js&"
import style0 from "./Suspended.vue?vue&type=style&index=0&lang=css&"
import style1 from "../../assets/less/custom/app-header.less?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Suspended.vue?vue&type=custom&index=0&blockType=q"
if (typeof block0 === 'function') block0(component)

export default component.exports
<template>
    <div class="topbar" id="top-bar">
        <div id="main_menu">
            <el-menu :router="true" class="el-menu-main" mode="horizontal">
                <div class="left">
                    <el-menu-item :key="1">
                        <img
                                alt="Account"
                                class="logo"
                                src="../../assets/img/auth/subscription-header-logo.svg"
                        />
                    </el-menu-item>
                </div>
                <div class="right">
                    <el-submenu class="profile-menu-item" index="">
                        <template slot="title">
                            <div class="user-info">
                                <div class="user-name" v-if='user && user.name'>{{user.name}}</div>
                                <div class="user-company text-capitalize" v-if='user && user.settings && user.settings.company'>{{user.settings.company}}</div>
                            </div>
                            <img :src="user.settings.avatar"
                                 alt=""
                                 class="img-circle profile-avatar"
                                 v-if="user && user.settings"
                                 width="42">
                        </template>

                        <el-menu-item class="text-center" style="height: auto">
                            <div class="user-identifire" v-if='user && user.name'>
                                {{ user.name }}
                            </div>
                        </el-menu-item>

                        <router-link to="/logout">
                            <el-menu-item index="logout">Logout</el-menu-item>
                        </router-link>
                    </el-submenu>
                </div>
            </el-menu>
            <div class="suspend_div">
                <div class="circle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 18.088 18.088">
                      <g id="Group_10998" data-name="Group 10998" transform="translate(-628 -479)">
                        <circle id="Ellipse_4260" data-name="Ellipse 4260" cx="9.044" cy="9.044" r="9.044" transform="translate(628 479)" fill="#ee5951"/>
                        <g id="Group_8569" data-name="Group 8569" transform="translate(638.103 492.812) rotate(180)">
                          <circle id="Ellipse_4259" data-name="Ellipse 4259" cx="0.994" cy="0.994" r="0.994" transform="translate(0)" fill="#fff"/>
                          <path id="Path_8229" data-name="Path 8229" d="M.595,0A.6.6,0,0,0,0,.595V5.648a.595.595,0,1,0,1.189,0V.595A.6.6,0,0,0,.595,0Z" transform="translate(0.435 3.61)" fill="#fff"/>
                        </g>
                      </g>
                    </svg>
                </div>
                <div class='main-alert-text'>
                    Your account has been suspended
                </div>
                <p class='text-section'>
                    Due to infringement of BigCommand Terms of Service, your account has been 
                    <span v-if='this.day'>temporarily suspended for
                        <span style="font-weight: bold"> {{this.day}} days</span>.
                    </span>
                    <span v-else>
                        suspended.
                    </span>
                </p>
                <p class='text-section' v-if='this.day'>
                    Starting 
                    <b>{{this.start_month}} {{this.start_day}}</b> to <b>{{this.end_month}} {{this.end_day}}, {{this.end_year}}</b>, you will not have
                    access to your account.
                    However, your projects and videos will continue to be available and stream wherever they are
                    currently embedded.
                </p>
                <p class='text-section' v-if='this.day'> Consider this a warning! Any other future infringement may lead to a permanent suspension.</p>


                <p class='text-section'>If you think this was a mistake, <a href="https://help.bigcommand.com">Contact Support</a></p>
            </div>
            <div class="more-options">
                <a href='' class='marginLinks info-links'>Help</a>
                <a href='' class='marginLinks info-links'>Privacy</a>
                <a href='' class='info-links'>Terms</a>
            </div>
            <div class="hamb"></div>
        </div>
    </div>


</template>

<script>
    export default {

        props: {
            user: {
                type: Object,
                default: function () {
                    return {};
                },
            },
        },
        data: () => {
            return {
                day: '',
                start_month: '',
                start_day: '',
                end_day: '',
                end_month: '',
                end_year: '',
            };
        },
        mounted() {
            this.getUserDetails();
        },
        methods: {
            getUserDetails()
            {
                if (this.$props.user) {
                    const startDate = Date.parse(this.user.suspended.created_at);
                    const endDate = Date.parse(this.user.suspended.ends_at);
                    var timeDiff = endDate - startDate;
                    const monthNames = ['', "January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                    ];
                    let start = new Date(this.user.suspended.created_at)
                    let end = new Date(this.user.suspended.ends_at)

                    this.start_day = start.getDate()
                    this.end_day = end.getDate()
                    this.end_year = end.getFullYear()

                    this.day = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                    this.start_month = monthNames[start.getMonth()];
                    this.end_month = monthNames[end.getMonth()];

                    console.log(this.start_month, this.end_month);
                }
            }
        },
        watch: {
            user(n, o)
            {
                this.getUserDetails();
            }
        }

    };
</script>
<q> </q>
<style>
    .suspend_div {
        width: 470px;
        height: max-content;
        padding: 20px;
        border: 1px solid #C9EDF8;
        border-radius: 12px;
        position: relative;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    .more-options {
        width: 470px;
        position: relative;
        left: 0;
        right: 0;
        text-align: right;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
    }
    .marginLinks {
        margin-right: 15px;
    }

    .info-links {
        font-size: 15px;
        color: #21455E;
    }

    .circle svg {
        width: 100%;
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
    }

    .el-menu--popup {
        z-index: 100;
        border: none;
        padding: 5px 0;
        border-radius: 2px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    }

    .el-menu--popup-bottom-start {
        margin-top: 5px;
    }

    .el-menu {
        border-right: solid 1px #e6e6e6;
        list-style: none;
        position: relative;
        margin: 0;
        padding-left: 0;
        background-color: #FFF;
    }

    .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
        background-color: #FFF;
        float: none;
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
        color: #909399;
    }

    .el-menu-item * {
        vertical-align: middle;
    }

    .el-menu--horizontal {
        border-right: none;
    }

    .el-menu-item:last-child {
        margin-right: 0;
    }

    .el-menu-item:first-child {
        margin-left: 0;
    }

    .el-menu--horizontal .el-menu-item {
        float: left;
        height: 60px;
        line-height: 60px;
        margin: 0;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        border-bottom: 5px solid transparent;
    }

    .el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
        min-width: 200px;
    }

    .el-menu--popup-bottom-start {
        margin-top: 5px;
    }

    .suspend_div p {
        color: #21455E;
        padding: 0 15px;
        line-height: 1.5;
        /*font-family: 'Helvetica Neue';*/
    }

    .text-section {
        padding-top: 12px !important;
    }

    .main-alert-text {
        width: 100%;
        text-align: center;
        margin-top: 5px;
        /*font-family: 'Helvetica Neue';*/
        font-size: 23px !important;
        letter-spacing: 0px;
        color: #EE5951 !important;
        font-weight: 500;
    }
</style>

<style lang="less" src="../../assets/less/custom/app-header.less"></style>